<template>
  <div class="contract-request">
    <v-breadcrumbs :items="items" class="bg-silver">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="bg-silver">
      <div class="px-5">
        <v-btn
          elevation="0"
          class="btn-icon bg--orange mx-auto text-heading"
          block
          rounded
          x-large
        >
          <span class="text-center w-100">{{ $t("buttons.appAddPoint") }}</span>
          <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
        <v-btn
          elevation="0"
          class="btn-icon bg--blue mx-auto mt-4 text-heading"
          block
          rounded
          x-large
        >
          <span class="text-center w-100">{{ $t("buttons.pointForApp") }}</span>
          <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </div>

      <div class="t-container mx-auto">
        <div class="t-container mx-auto bg-smoke shadow-smoke">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <p class="text-title--szm text-dark">
                  {{ $t("contractReq.historyStatus") }}
                </p>
              </div>

              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" v-for="(item, i) in history" :key="i">
              <div class="d-flex justify-space-between text-medium-szm">
                <p>{{ item.day }}</p>
                <p>{{ item.infoPoint }}</p>
                <p class="color--orange">
                  {{ item.point }}{{ $t("contract.year.point") }}
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contract-request',
  data () {
    return {
      items: [
        {
          text: this.$t('title.main'),
          disabled: false,
          href: '/home'
        },
        {
          text: this.$t('contractDetail.contract'),
          disabled: false,
          href: '/home/contract-detail'
        },
        {
          text: this.$t('buttons.plusPoints'),
          disabled: true,
          href: ''
        }
      ],
      history: [
        {
          day: '2020-09-14',
          infoPoint: this.$t('contractReq.forwarding'),
          point: '00'
        },
        {
          day: '2020-08-23',
          infoPoint: this.$t('contractReq.addPoint'),
          point: '00'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@use '../../assets/styles/modules/variables-scss' as v;

.contract-request > div {
  margin-top: 1px;
  .px-5 {
    padding-top: 24px;
    padding-bottom: 39px;
  }
}
.shadow-smoke {
  max-width: 500px;
  margin-bottom: 30px;
}
.v-divider {
  margin-top: 18px;
}
.theme--light.v-btn {
  color: var(--color__white) !important;
}
@media (max-width: v.$breakpoint__Extra-small) {
  .text-heading {
    font-size: var(--font-size__medium) !important;
  }
  .text-title--szm {
    font-size: var(--font-size__medium--szm) !important;
  }
  .text-medium-szm {
    font-size: var(--font-size__small) !important;
  }
  .contract-request > div {
    .px-5 {
      padding-bottom: 0;
    }
  }
  .shadow-smoke {
    margin-bottom: 0;
  }
}
</style>
